import { useState, useCallback, useEffect } from "react";
import Header from "components/Header";
import { useRouter } from "next/router";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { createServerSupabaseClient } from "@supabase/auth-helpers-nextjs";
import { Box, Container, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import BlackButton from "@/components/BlackButton";
import StyledLink from "@/components/StyledLink";
import StyledStack from "@/components/StyledStack";
import StyledHeading from "@/components/StyledHeading";
import StyledInput from "@/components/StyledInput";
import ErrorText from "@/components/shared/typography/ErrorText";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Layout from "@/components/Layout";
import { updateProfile } from "hooks/useProfile";
import { timeNow } from "utils/helpers";

const loginSchema = yup.object().shape({
  email: yup.string().email().trim().nullable().required(),
  password: yup.string().min(6).trim().nullable().required(),
});

const Login = ({ redirectUrl }) => {
  const [isLoading, setLoading] = useState(false);
  const [formError, setFormError] = useState(null);
  const supabaseClient = useSupabaseClient();
  const router = useRouter();

  const { register, handleSubmit, formState } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    shouldFocusError: true,
    resolver: yupResolver(loginSchema),
  });
  const { errors } = formState;

  const onLogin = useCallback(
    async (formData) => {
      setLoading(true);
      setFormError(null);
      try {
        const { data, error } = await supabaseClient.auth.signInWithPassword(
          formData
        );
        if (error) {
          throw error;
        } else {
          await updateProfile(supabaseClient, data.user.id, {
            last_login: timeNow(),
          });
          router.push(redirectUrl);
        }
      } catch (error) {
        setFormError(error.message);
        setLoading(false);
      }
    },
    [router, supabaseClient, redirectUrl]
  );

  useEffect(() => {
    // Prefetch the next page
    router.prefetch(redirectUrl);
  }, [redirectUrl, router]);

  return (
    <Layout>
      <Header browserTitle="WellFitly - Log in" />
      <Container
        as={SimpleGrid}
        maxW={"100vw"}
        bg={"gray.50"}
        minH={"75vh"}
        spacing={{ base: 10, lg: 32 }}
        py={{ base: 10, sm: 20, lg: 24 }}
      >
        <StyledStack>
          <Stack align={"center"} mb={6}>
            <StyledHeading
              mainTitle="Log in to WellFitly"
              subTitle="Please log in to access members only area."
            />
            <Text>
              Don&apos;t have an account?{" "}
              <StyledLink href="/sign-up">Sign up</StyledLink>
            </Text>
          </Stack>
          <Box as={"form"} mt={10} onSubmit={handleSubmit(onLogin)}>
            <Stack spacing={4}>
              <StyledInput
                id="email"
                name="email"
                placeholder="Email"
                type="email"
                isInvalid={errors.email}
                register={register}
                errors={errors}
              />
              <StyledInput
                id="password"
                name="password"
                placeholder="Password"
                type="password"
                isInvalid={errors.password}
                register={register}
                errors={errors}
              />
              <Stack spacing={4}>
                <Stack direction={{ base: "column", sm: "row" }}>
                  <StyledLink href="/forgot">Forgot password?</StyledLink>
                </Stack>
                <BlackButton
                  type="submit"
                  id="btn-create-account"
                  isLoading={isLoading}
                  buttonText="Log in"
                />
                <Box>{formError && <ErrorText text={formError} />}</Box>
              </Stack>
            </Stack>
          </Box>
        </StyledStack>
      </Container>
    </Layout>
  );
};

export const getServerSideProps = async ({ req, res, query }) => {
  const supabaseServerClient = createServerSupabaseClient({ req, res });
  const redirectedFrom = query.redirectedFrom || req.headers.referer;
  const redirectUrl = redirectedFrom && !redirectedFrom.toLowerCase().includes('login') ? redirectedFrom : `/dashboard`;
  const {
    data: { session },
  } = await supabaseServerClient.auth.getSession();

  const {
    data: { user },
  } = await supabaseServerClient.auth.getUser();

  if (session && user) {
    await updateProfile(supabaseServerClient, user.id, {
      last_login: timeNow(),
    });
    return {
      redirect: {
        permanent: false,
        destination: redirectUrl,
      },
    };
  }

  return {
    props: {
      redirectUrl,
    },
  };
};

export default Login;
