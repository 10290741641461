import { Link } from "@chakra-ui/react";

const StyledLink = ({ children, ...rest }) => {
  return (
    <Link style={{ textDecoration: "underline" }} {...rest}>
      {children}
    </Link>
  );
};

export default StyledLink;
