import { memo } from "react";
import { Heading, Text } from "@chakra-ui/react";

const StyledHeading = ({ mainTitle, subTitle }) => {
  return (
    <>
      <Heading
        color={"gray.800"}
        lineHeight={1.1}
        fontFamily={"YesevaOne"}
        fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
      >
        {mainTitle}
      </Heading>
      <Text color={"gray.500"} fontSize={{ base: "sm", sm: "md" }}>
        {subTitle}
      </Text>
    </>
  );
};

export default memo(StyledHeading);
