const { Stack } = require("@chakra-ui/react");

const StyledStack = ({ children }) => {
  return (
    <Stack
      bg={"gray.50"}
      rounded={"xl"}
      p={{ base: 4, sm: 6, md: 8 }}
      mx={"auto"}
      h="max-content"
    >
      {children}
    </Stack>
  );
};

export default StyledStack;