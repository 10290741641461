import { memo } from "react";
import { Input, FormControl, FormLabel } from "@chakra-ui/react";
import ErrorText from "./shared/typography/ErrorText";

const StyledInput = ({
  id,
  type,
  placeholder,
  label,
  border = 0,
  bg = "gray.100",
  color = "gray.500",
  name = "input",
  value = null,
  hasLabel = false,
  register = () => {},
  errors = {},
  ...rest
}) => {
  if (!id) {
    const randomValue = Math.random() * 12345678;
    id = `${name}_${randomValue}`;
  }
  return (
    <FormControl id={id}>
      {label ? (
        <FormLabel mt={4} mb={2}>
          {label}
        </FormLabel>
      ) : null}
      <Input
        {...rest}
        type={type}
        placeholder={placeholder}
        bg={bg}
        border={border}
        color={color}
        _placeholder={{
          color: "gray.500",
        }}
        errorBorderColor="red.300"
        {...register(name, { value })}
      />
      {errors[name] && <ErrorText text={errors[name].message} />}
    </FormControl>
  );
};

export default memo(StyledInput);
